/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { navigate } from '@reach/router';
// import { StaticQuery, graphql } from 'gatsby';
import { Row, Col, Collapse } from 'reactstrap';
import { Formik, Form } from 'formik';
import Checkbox from '../checkbox/checkbox';
import { trackAnalytics } from '../../helpers/trackAnalytics';
import { MasterDataContext } from '../../store/masterDataContext';

//style
import { Fonts } from '../../global.css';
import { Advanced } from './advanced-search.css';

class AdvancedSearch extends React.PureComponent {
  static contextType = MasterDataContext;

  constructor(props) {
    super(props);

    this.state = {
      selectedOptions: null,
      SearchTerm: '',
      collapseMC: false,
      collapseST: false,
      collapsePH: false,
      collapseSEX: false,
      collapseAGE: false,
      collapseRES: false,
      collapseHV: false,
      collapseTY: false,
      collapseDoc: false,
      collapseVac: false,
      patientData: false,
      hashLocation: null,
    };
    this.selectedFilter = [];
    this.healthOption = '';
    this.searchParams = null;
    this.previousValues = null;
    this.selected = null;
  }
  patientData = () => {
    let collapse = this.state.patientData;
    this.setState({ patientData: !collapse });
    trackAnalytics('FilterToggle', {
      FilterName: 'Patient Level Data',
      Toggle: collapse ? 'off' : 'on',
      PositionInList: 8,
      CurrentValues: this.getValues('patientLevel', []),
    });
  };
  toggleMC = () => {
    let collapse = this.state.collapseMC;
    this.setState({ collapseMC: !collapse });
    trackAnalytics('FilterToggle', {
      FilterName: 'Medical Conditions',
      Toggle: collapse ? 'off' : 'on',
      PositionInList: 0,
      CurrentValues: this.getValues('Conditions', []),
    });
  };
  toggleST = () => {
    let collapse = this.state.collapseST;
    this.setState({ collapseST: !collapse });
    trackAnalytics('FilterToggle', {
      FilterName: 'Study Status',
      Toggle: collapse ? 'off' : 'on',
      PositionInList: 1,
      CurrentValues: this.getValues('Status', []),
    });
  };
  togglePH = () => {
    let collapse = this.state.collapsePH;
    this.setState({ collapsePH: !collapse });
    trackAnalytics('FilterToggle', {
      FilterName: 'Phases',
      Toggle: collapse ? 'off' : 'on',
      PositionInList: 2,
      CurrentValues: this.getValues('phases', []),
    });
  };
  toggleSEX = () => {
    let collapse = this.state.collapseSEX;
    this.setState({ collapseSEX: !collapse });
    trackAnalytics('FilterToggle', {
      FilterName: 'Gender',
      Toggle: collapse ? 'off' : 'on',
      PositionInList: 4,
      CurrentValues: this.getValues('gender', []),
    });
  };
  toggleAGE = () => {
    let collapse = this.state.collapseAGE;
    this.setState({ collapseAGE: !collapse });
    trackAnalytics('FilterToggle', {
      FilterName: 'Age',
      Toggle: collapse ? 'off' : 'on',
      PositionInList: 3,
      CurrentValues: this.getValues('ageRange', []),
    });
  };
  toggleRES = () => {
    let collapse = this.state.collapseRES;
    this.setState({ collapseRES: !collapse });
    trackAnalytics('FilterToggle', {
      FilterName: 'Results',
      Toggle: collapse ? 'off' : 'on',
      PositionInList: 7,
      CurrentValues: this.getValues('HasCTGovResult', []),
    });
  };
  toggleHV = () => {
    let collapse = this.state.collapseHV;
    this.setState({ collapseHV: !collapse });
  };
  toggleTY = () => {
    let collapse = this.state.collapseTY;
    this.setState({ collapseTY: !collapse });
    trackAnalytics('FilterToggle', {
      FilterName: 'Study Type',
      Toggle: collapse ? 'off' : 'on',
      PositionInList: 5,
      CurrentValues: this.getValues('studyType', []),
    });
  };
  toggleDoc = () => {
    let collapse = this.state.collapseDoc;
    this.setState({ collapseDoc: !collapse });
    trackAnalytics('FilterToggle', {
      FilterName: 'Documents',
      Toggle: collapse ? 'off' : 'on',
      PositionInList: 6,
      CurrentValues: this.getValues('documents', []),
    });
  };
  toggleVac = () => {
    let collapse = this.state.collapseVac;
    this.setState({ collapseVac: !collapse });
    trackAnalytics('FilterToggle', {
      FilterName: 'Vaccine Studies',
      Toggle: collapse ? 'off' : 'on',
      PositionInList: 9,
      CurrentValues: this.getValues('SponsorUnits', []),
    });
  };

  setHashLocation = filterSec => {
    window !== undefined && !window.enableAnchors
      ? (window.enableAnchors = true)
      : null;
    this.props.isMobileFilter
      ? this.setState({ hashLocation: filterSec })
      : null;
  };

  buildSelectedCon = () => {
    const masterData =
      this.context && this.context.masterData ? this.context.masterData : {};

    this.selected = Object.assign(
      {},
      ...Object.keys(masterData).map(key => ({
        [key]: [],
      }))
    );
    Object.values(this.selectedFilter).forEach(selectedValue => {
      let values = [selectedValue];
      if (values.length && masterData) {
        values.forEach(internalValue => {
          Object.keys(masterData).forEach(key => {
            if (Array.isArray(masterData[key])) {
              masterData[key].forEach(item => {
                if (item.InternalValue === internalValue) {
                  this.selected[key].push({
                    displayValue: item.DisplayValue,
                    internalValue: item.InternalValue,
                  });
                }

                if (item.Children && Array.isArray(item.Children)) {
                  item.Children.forEach(subItem => {
                    if (subItem.InternalValue === internalValue) {
                      this.selected[key].push({
                        displayValue: subItem.DisplayValue,
                        internalValue: subItem.InternalValue,
                        parent: item.InternalValue,
                      });
                    }
                  });
                }
              });
            }
          });
        });
      }
    });
  };

  prevMasterData = null;

  componentDidUpdate() {
    try {
      this.searchParams = new URL(location.href).searchParams;
    } catch (e) {
      this.searchParams = new URLSearchParams('');
    }

    if (this.state.reset === true) {
      this.setState({ reset: false });
    }

    if (this.context) {
      const { masterData } = this.context;

      if (masterData !== this.prevMasterData) {
        this.prevMasterData = masterData;

        console.log(this.selected);

        this.buildSelectedCon();
      }
    }
  }

  componentDidMount() {
    let { location } = this.props;
    const masterData =
      this.context && this.context.masterData ? this.context.masterData : {};

    this.selected = Object.assign(
      {},
      ...Object.keys(masterData).map(key => ({
        [key]: [],
      }))
    );
    try {
      this.searchParams = new URL(location.href).searchParams;
    } catch (e) {
      this.searchParams = new URLSearchParams('');
    }
    if (this.searchParams) {
      this.setState({ formInitialized: true });
    }
  }

  handleSubmit(values) {
    let { location, searchCallBack } = this.props,
      searchParams = null;
    try {
      searchParams = new URL(window ? window.location.href : location.href)
        .searchParams;
    } catch (e) {
      searchParams = new URLSearchParams('');
    }

    let finalQuery = {};
    searchParams.forEach((value, key) => {
      finalQuery[key] = value;
    });
    Object.keys(values).forEach(key => {
      finalQuery[key] = values[key];
    });

    if (
      finalQuery.gender.length > 0 &&
      finalQuery.gender.indexOf('GENDER_ALL') < 0
    ) {
      finalQuery.gender.push('GENDER_ALL');
    }
    if (finalQuery.gender.length == 1) {
      finalQuery.gender.splice('GENDER_ALL'), 1;
    }
    this.selectedFilter = Object.values(finalQuery).reduce(
      (acc, val) => acc.concat(val),
      []
    );

    this.buildSelectedCon();

    let newSearchQuery = Object.keys(finalQuery)
      .map(
        k =>
          encodeURIComponent(k) +
          '=' +
          encodeURIComponent(
            Array.isArray(finalQuery[k])
              ? finalQuery[k].join('~')
              : `${finalQuery[k]}`
          )
      )
      .join('&');
    if (newSearchQuery && `?${newSearchQuery}` !== window.location.search) {
      navigate(
        `/en/results/?${newSearchQuery}${
          this.state.hashLocation !== null ? `#${this.state.hashLocation}` : ''
        }`,
        { replace: true }
      );
      if (searchCallBack && typeof searchCallBack === 'function') {
        setTimeout(() => {
          searchCallBack();
        });
      }
    }
  }

  getValues(paramnName, defaultValue) {
    return (
      (this.searchParams &&
        this.searchParams.get(paramnName) &&
        this.searchParams.get(paramnName).split('~')) ||
      defaultValue
    );
  }

  render() {
    let { data } = this.props;
    const masterData =
      this.context && this.context.masterData ? this.context.masterData : {};

    let conditions =
      masterData &&
      masterData.CONDITION &&
      masterData.CONDITION.sort((a, b) => a.SortOrder - b.SortOrder);
    let genders =
      masterData &&
      masterData.GENDER &&
      masterData.GENDER.sort((a, b) => a.SortOrder - b.SortOrder);
    let ages =
      masterData &&
      masterData.AGERANGE &&
      masterData.AGERANGE.sort((a, b) => a.SortOrder - b.SortOrder);
    let statuses =
      masterData &&
      masterData.RECSTATUS &&
      masterData.RECSTATUS.sort((a, b) => a.SortOrder - b.SortOrder);
    let phases =
      masterData &&
      masterData.PHASE &&
      masterData.PHASE.sort((a, b) => a.SortOrder - b.SortOrder);
    let patientData =
      masterData &&
      masterData.PATIENT_LEVEL_SHARING &&
      masterData.PATIENT_LEVEL_SHARING.sort(
        (a, b) => a.SortOrder - b.SortOrder
      );
    let studyType =
      masterData &&
      masterData.STUDY_TYPE &&
      masterData.STUDY_TYPE.sort((a, b) => a.SortOrder - b.SortOrder);
    let availableDocs =
      masterData && masterData.ATTACHTYPE && masterData.ATTACHTYPE
        ? masterData.ATTACHTYPE.sort((a, b) => a.SortOrder - b.SortOrder)
        : [];

    let idSuffix = Math.floor(Math.random(400) * 10000);

    return (
      <Fonts>
        <Advanced>
          {this.searchParams && (
            <Formik
              enableReinitialize={false}
              initialValues={{
                Conditions: this.getValues('Conditions', []),
                phases: this.getValues('phases', []),
                HasCTGovResult: this.getValues('HasCTGovResult', []),
                patientLevel: this.getValues('patientLevel', []),
                gender: this.getValues('gender', []),
                ageRange: this.getValues('ageRange', []),
                Status: this.getValues('Status', []),
                studyType: this.getValues('studyType', []),
                documents: this.getValues('documents', []),
                SponsorUnits: this.getValues('SponsorUnits', []),
              }}
              onSubmit={(values, actions) => {
                actions.setSubmitting(true);
                values.Conditions = this.selectedFilter;
                this.handleSubmit(values);
                actions.setSubmitting(true);
                setTimeout(() => {
                  this.props.pageSearch ? this.props.pageSearch() : null;
                }, 1000);
              }}
              render={props => {
                if (props.values && props.initialValues) {
                  if (
                    JSON.stringify(props.values) !==
                    JSON.stringify(this.previousValues)
                  ) {
                    setTimeout(() => {
                      this.previousValues = props.values;
                      this.handleSubmit(props.values);
                    });
                  }
                }
                return (
                  <Form>
                    <Row>
                      <Col className={'hero-filter'} xs={12}>
                        <p>{data.advancedSearchJson.search.filter}</p>
                        <div
                          className={'reset-button'}
                          onClick={() => {
                            navigate(
                              '/en/results/?ageRange=&phases=&documents=&Keyword=&studyType=&gender=&HasCTGovResult=&patientLevel=&Status=&Latitude=&Longitude=&LocationName=&MileRadius=&Conditions='
                            ) &&
                              window &&
                              window.location.reload();
                          }}
                        >
                          {data.advancedSearchJson.search.clear}
                        </div>
                      </Col>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'column',
                        }}
                      >
                        {conditions && conditions.length && (
                          <Col
                            className={'check-box-display' + ' check-box-first'}
                            id="filter-medical-cond"
                          >
                            <div
                              onClick={this.toggleMC}
                              className={'title-section'}
                            >
                              <h5>
                                {
                                  data.advancedSearchJson.search
                                    .medicalCondition
                                }
                              </h5>
                              <img
                                src="/icons/dropdown_arrow.svg"
                                alt="icon"
                                className={
                                  this.state.collapseMC === true
                                    ? 'arrow-position'
                                    : ''
                                }
                              />
                            </div>
                            <div
                              className={'filter-items'}
                              hidden={
                                this.state.collapseMC === true ||
                                (this.selected &&
                                  this.selected.CONDITION &&
                                  this.selected.CONDITION.length < 1)
                              }
                            >
                              {this.selected &&
                                this.selected.CONDITION &&
                                this.selected.CONDITION.map((cond, index) => {
                                  return (
                                    <Checkbox
                                      key={index}
                                      value={cond.internalValue}
                                      name="Conditions"
                                      displayvalue={cond.displayValue}
                                      condchildren={
                                        cond.Children && cond.Children.length
                                          ? cond.Children.map(
                                              child => child.InternalValue
                                            )
                                          : null
                                      }
                                      id={cond.internalValue + idSuffix}
                                      parent={cond.parent}
                                      setHashCallback={() =>
                                        this.setHashLocation(
                                          'filter-medical-cond'
                                        )
                                      }
                                    />
                                  );
                                })}
                            </div>
                            <Collapse isOpen={this.state.collapseMC}>
                              <div className={'cond-style boxes-style'}>
                                {conditions.map((condition, index) => (
                                  <div
                                    key={index}
                                    className={'check-box-align' + ' cond-div'}
                                  >
                                    <div
                                      className={'cond-parent'}
                                      style={{ width: '100%' }}
                                    >
                                      <Checkbox
                                        value={condition.InternalValue}
                                        name="Conditions"
                                        displayvalue={condition.DisplayValue}
                                        id={condition.InternalValue + idSuffix}
                                        condchildren={
                                          condition.Children &&
                                          condition.Children.length
                                            ? condition.Children.map(
                                                child => child.InternalValue
                                              )
                                            : null
                                        }
                                        setHashCallback={() =>
                                          this.setHashLocation(
                                            'filter-medical-cond'
                                          )
                                        }
                                      />
                                    </div>

                                    {condition.Children &&
                                      condition.Children.length && (
                                        <div
                                          className={
                                            'check-box-display-children'
                                          }
                                        >
                                          {condition.Children.map(
                                            (subCondition, subIndex) => (
                                              <div
                                                className={'check-box-align'}
                                                key={subIndex}
                                              >
                                                <Checkbox
                                                  value={
                                                    subCondition.InternalValue
                                                  }
                                                  id={
                                                    subCondition.InternalValue +
                                                    idSuffix
                                                  }
                                                  name="Conditions"
                                                  displayvalue={
                                                    subCondition.DisplayValue
                                                  }
                                                  parent={
                                                    condition.InternalValue
                                                  }
                                                  setHashCallback={() =>
                                                    this.setHashLocation(
                                                      'filter-medical-cond'
                                                    )
                                                  }
                                                />
                                              </div>
                                            )
                                          )}
                                        </div>
                                      )}
                                  </div>
                                ))}
                              </div>
                            </Collapse>
                          </Col>
                        )}
                        {statuses && statuses.length && (
                          <Col
                            className={'check-box-display'}
                            id="filter-status"
                          >
                            <div
                              onClick={this.toggleST}
                              className={'title-section'}
                            >
                              <h5>{data.advancedSearchJson.search.status}</h5>

                              <img
                                src="/icons/dropdown_arrow.svg"
                                alt="icon"
                                className={
                                  this.state.collapseST === true
                                    ? 'arrow-position'
                                    : ''
                                }
                              />
                            </div>
                            <div
                              className={'filter-items'}
                              hidden={
                                this.state.collapseST === true ||
                                (this.selected &&
                                  this.selected.RECSTATUS &&
                                  this.selected.RECSTATUS.length < 1)
                              }
                            >
                              {this.selected &&
                                this.selected.RECSTATUS &&
                                this.selected.RECSTATUS.map((cond, index) => {
                                  return (
                                    <Checkbox
                                      key={index}
                                      value={cond.internalValue}
                                      id={cond.internalValue + idSuffix}
                                      name="Status"
                                      displayvalue={cond.displayValue}
                                      setHashCallback={() =>
                                        this.setHashLocation('filter-status')
                                      }
                                    />
                                  );
                                })}
                            </div>
                            <Collapse isOpen={this.state.collapseST}>
                              <div className={'boxes-style'}>
                                {statuses.map((status, index) =>
                                  status.InternalValue.indexOf(
                                    'RECSTATUS_RELIQUISHED'
                                  ) > -1 ? null : (
                                    <Col
                                      key={index}
                                      className={'check-box-align'}
                                    >
                                      <Checkbox
                                        value={status.InternalValue}
                                        id={status.InternalValue + idSuffix}
                                        name="Status"
                                        displayvalue={status.DisplayValue}
                                        setHashCallback={() =>
                                          this.setHashLocation('filter-status')
                                        }
                                      />
                                    </Col>
                                  )
                                )}
                              </div>
                            </Collapse>
                          </Col>
                        )}
                        {phases && phases.length && (
                          <Col
                            className={'check-box-display'}
                            id="filter-phases"
                          >
                            <div
                              onClick={this.togglePH}
                              className={'title-section'}
                            >
                              <h5>{data.advancedSearchJson.search.phases}</h5>
                              <img
                                src="/icons/dropdown_arrow.svg"
                                alt="icon"
                                className={
                                  this.state.collapsePH === true
                                    ? 'arrow-position'
                                    : ''
                                }
                              />
                            </div>
                            <div
                              className={'filter-items'}
                              hidden={
                                this.state.collapsePH === true ||
                                (this.selected &&
                                  this.selected.PHASE &&
                                  this.selected.PHASE.length < 1)
                              }
                            >
                              {this.selected &&
                                this.selected.PHASE &&
                                this.selected.PHASE.map((cond, index) => {
                                  return (
                                    <Checkbox
                                      key={index}
                                      value={cond.internalValue}
                                      id={cond.internalValue + idSuffix}
                                      name="phases"
                                      displayvalue={cond.displayValue}
                                      setHashCallback={() =>
                                        this.setHashLocation('filter-phases')
                                      }
                                    />
                                  );
                                })}
                            </div>
                            <Collapse isOpen={this.state.collapsePH}>
                              <div className={'boxes-style'}>
                                {' '}
                                {phases.map((phase, index) => (
                                  <Col
                                    key={index}
                                    className={'check-box-align'}
                                  >
                                    <Checkbox
                                      value={phase.InternalValue}
                                      id={phase.InternalValue + idSuffix}
                                      name="phases"
                                      displayvalue={phase.DisplayValue}
                                      setHashCallback={() =>
                                        this.setHashLocation('filter-phases')
                                      }
                                    />
                                  </Col>
                                ))}
                              </div>
                            </Collapse>
                          </Col>
                        )}
                        {ages && ages.length && (
                          <Col className={'check-box-display'} id="filter-ages">
                            <div
                              onClick={this.toggleAGE}
                              className={'title-section'}
                            >
                              <h5>{data.advancedSearchJson.search.age}</h5>

                              <img
                                src="/icons/dropdown_arrow.svg"
                                alt="icon"
                                className={
                                  this.state.collapseAGE === true
                                    ? 'arrow-position'
                                    : ''
                                }
                              />
                            </div>
                            <div
                              className={'filter-items'}
                              hidden={
                                this.state.collapseAGE === true ||
                                (this.selected &&
                                  this.selected.AGERANGE &&
                                  this.selected.AGERANGE.length < 1)
                              }
                            >
                              {this.selected &&
                                this.selected.AGERANGE &&
                                this.selected.AGERANGE.map((cond, index) => {
                                  return (
                                    <Checkbox
                                      key={index}
                                      value={cond.internalValue}
                                      id={cond.internalValue + idSuffix}
                                      name="ageRange"
                                      displayvalue={cond.displayValue}
                                      setHashCallback={() =>
                                        this.setHashLocation('filter-ages')
                                      }
                                    />
                                  );
                                })}
                            </div>
                            <Collapse isOpen={this.state.collapseAGE}>
                              <div className={'boxes-style'}>
                                {ages.map((age, index) => (
                                  <Col
                                    key={index}
                                    className={'check-box-align'}
                                  >
                                    <Checkbox
                                      name="ageRange"
                                      value={age.InternalValue}
                                      id={age.InternalValue + idSuffix}
                                      displayvalue={age.DisplayValue}
                                      setHashCallback={() =>
                                        this.setHashLocation('filter-ages')
                                      }
                                    />
                                  </Col>
                                ))}
                              </div>
                            </Collapse>
                          </Col>
                        )}
                        {genders && genders.length && (
                          <Col
                            className={'check-box-display'}
                            id="filter-genders"
                          >
                            <div
                              onClick={this.toggleSEX}
                              className={'title-section'}
                            >
                              <h5>{data.advancedSearchJson.search.gender}</h5>

                              <img
                                src="/icons/dropdown_arrow.svg"
                                alt="icon"
                                className={
                                  this.state.collapseSEX === true
                                    ? 'arrow-position'
                                    : ''
                                }
                              />
                            </div>
                            <div
                              className={'filter-items'}
                              hidden={
                                this.state.collapseSEX === true ||
                                (this.selected &&
                                  this.selected.GENDER &&
                                  this.selected.GENDER.length < 1)
                              }
                            >
                              {this.selected &&
                                this.selected.GENDER &&
                                this.selected.GENDER.map((cond, index) => {
                                  return cond.internalValue.indexOf('ALL') >
                                    -1 ? null : (
                                    <Checkbox
                                      key={index}
                                      value={cond.internalValue}
                                      id={cond.internalValue + idSuffix}
                                      name="gender"
                                      displayvalue={cond.displayValue}
                                      setHashCallback={() =>
                                        this.setHashLocation('filter-genders')
                                      }
                                    />
                                  );
                                })}
                            </div>
                            <Collapse isOpen={this.state.collapseSEX}>
                              <div className={'boxes-style'}>
                                {genders.map((gender, index) =>
                                  gender.InternalValue.indexOf('ALL') >
                                  -1 ? null : (
                                    <Col
                                      key={index}
                                      className={'check-box-align'}
                                    >
                                      <Checkbox
                                        name="gender"
                                        value={gender.InternalValue}
                                        id={gender.InternalValue + idSuffix}
                                        displayvalue={gender.DisplayValue}
                                        setHashCallback={() =>
                                          this.setHashLocation('filter-genders')
                                        }
                                      />
                                    </Col>
                                  )
                                )}
                              </div>
                            </Collapse>
                          </Col>
                        )}
                        {studyType && studyType.length && (
                          <Col
                            className={'check-box-display'}
                            id="filter-study-types"
                          >
                            <div
                              onClick={this.toggleTY}
                              className={'title-section'}
                            >
                              <h5>
                                {data.advancedSearchJson.search.studyType}
                              </h5>
                              <img
                                src="/icons/dropdown_arrow.svg"
                                alt="icon"
                                className={
                                  this.state.collapseTY === true
                                    ? 'arrow-position'
                                    : ''
                                }
                              />
                            </div>
                            <div
                              className={'filter-items'}
                              hidden={
                                this.state.collapseTY ||
                                (this.selected &&
                                  this.selected.STUDY_TYPE &&
                                  this.selected.STUDY_TYPE.length < 1)
                              }
                            >
                              {this.selected &&
                                this.selected.STUDY_TYPE &&
                                this.selected.STUDY_TYPE.map((cond, index) => {
                                  return (
                                    <Checkbox
                                      key={index}
                                      value={cond.internalValue}
                                      id={cond.internalValue + idSuffix}
                                      name="studyType"
                                      displayvalue={cond.displayValue}
                                      setHashCallback={() =>
                                        this.setHashLocation(
                                          'filter-study-types'
                                        )
                                      }
                                    />
                                  );
                                })}
                            </div>
                            <Collapse isOpen={this.state.collapseTY}>
                              <div className={'boxes-style'}>
                                {studyType.map((study, index) => (
                                  <Col
                                    className={'check-box-align'}
                                    key={index}
                                  >
                                    <Checkbox
                                      name="studyType"
                                      value={study.InternalValue}
                                      id={study.InternalValue + idSuffix}
                                      displayvalue={study.DisplayValue}
                                      setHashCallback={() =>
                                        this.setHashLocation(
                                          'filter-study-types'
                                        )
                                      }
                                    />
                                  </Col>
                                ))}
                              </div>
                            </Collapse>
                          </Col>
                        )}
                        {availableDocs && availableDocs.length ? (
                          <Col
                            className={'check-box-display'}
                            id="filter-available-docs"
                          >
                            <div
                              onClick={this.toggleDoc}
                              className={'title-section'}
                            >
                              <h5>
                                {data.advancedSearchJson.search.availableDoc}
                              </h5>
                              <img
                                src="/icons/dropdown_arrow.svg"
                                alt="icon"
                                className={
                                  this.state.collapseDoc === true
                                    ? 'arrow-position'
                                    : ''
                                }
                              />
                            </div>
                            <div
                              className={'filter-items'}
                              hidden={
                                this.state.collapseDoc === true ||
                                (this.selected &&
                                  this.selected.ATTACHTYPE &&
                                  this.selected.ATTACHTYPE.length < 1)
                              }
                            >
                              {this.selected &&
                                this.selected.ATTACHTYPE &&
                                this.selected.ATTACHTYPE.map((cond, index) => {
                                  return (
                                    <Checkbox
                                      key={index}
                                      value={cond.internalValue}
                                      id={cond.internalValue + idSuffix}
                                      name="documents"
                                      displayvalue={cond.displayValue}
                                      setHashCallback={() =>
                                        this.setHashLocation(
                                          'filter-available-docs'
                                        )
                                      }
                                    />
                                  );
                                })}
                            </div>
                            <Collapse isOpen={this.state.collapseDoc}>
                              <div className={'boxes-style'}>
                                {availableDocs.map((availableDoc, index) => (
                                  <Col
                                    key={index}
                                    className={
                                      'check-box-align' + ' doc-section'
                                    }
                                  >
                                    <Checkbox
                                      value={availableDoc.InternalValue}
                                      id={availableDoc.InternalValue + idSuffix}
                                      name="documents"
                                      displayvalue={availableDoc.DisplayValue}
                                      setHashCallback={() =>
                                        this.setHashLocation(
                                          'filter-available-docs'
                                        )
                                      }
                                    />
                                  </Col>
                                ))}
                              </div>
                            </Collapse>
                          </Col>
                        ) : null}
                        <Col
                          className={'check-box-display'}
                          id="filter-results"
                        >
                          <div
                            onClick={this.toggleRES}
                            className={'title-section'}
                          >
                            <h5>{'Results'}</h5>

                            <img
                              src="/icons/dropdown_arrow.svg"
                              alt="icon"
                              className={
                                this.state.collapseRES === true
                                  ? 'arrow-position'
                                  : ''
                              }
                            />
                          </div>
                          <div
                            className={'filter-items'}
                            hidden={
                              this.state.collapseRES === true ||
                              props.values.HasCTGovResult.length < 1
                            }
                          >
                            {props.values.HasCTGovResult.includes('true') ? (
                              <Checkbox
                                id={'withresults'}
                                value={'true'}
                                displayvalue={'Studies with Results'}
                                name={'HasCTGovResult'}
                                setHashCallback={() =>
                                  this.setHashLocation('filter-results')
                                }
                              />
                            ) : null}
                            {props.values.HasCTGovResult.includes('false') ? (
                              <Checkbox
                                id={'withoutresults'}
                                value={'false'}
                                displayvalue={'Studies without Results'}
                                name={'HasCTGovResult'}
                                setHashCallback={() =>
                                  this.setHashLocation('filter-results')
                                }
                              />
                            ) : null}
                          </div>
                          <Collapse isOpen={this.state.collapseRES}>
                            <div className={'boxes-style'}>
                              <div className={'checkboxes-section'}>
                                {/* study with results field
                            will send as param HasResults=true or empty
                          */}
                                <Checkbox
                                  id={'withresults'}
                                  value={'true'}
                                  displayvalue={'Studies with Results'}
                                  name={'HasCTGovResult'}
                                  setHashCallback={() =>
                                    this.setHashLocation('filter-results')
                                  }
                                />
                                {/* study without results field
                              will send as param IsReleasedToCtGov=true or empty
                          */}
                                <Checkbox
                                  id={'withoutresults'}
                                  value={'false'}
                                  displayvalue={'Studies without Results'}
                                  name={'HasCTGovResult'}
                                  setHashCallback={() =>
                                    this.setHashLocation('filter-results')
                                  }
                                />
                              </div>
                            </div>
                          </Collapse>
                        </Col>
                        {patientData && patientData.length && (
                          <Col
                            className={'check-box-display'}
                            id="filter-patient-data"
                          >
                            <div
                              onClick={this.patientData}
                              className={'title-section'}
                            >
                              <h5>
                                {data.advancedSearchJson.search.patientData}
                              </h5>

                              <img
                                src="/icons/dropdown_arrow.svg"
                                alt="icon"
                                className={
                                  this.state.collapseDoc === true
                                    ? 'arrow-position'
                                    : ''
                                }
                              />

                              {/* <h3>{data.advancedJson.availableDoc}</h3> */}
                            </div>
                            <div
                              className={'filter-items'}
                              hidden={
                                this.state.patientData === true ||
                                (this.selected &&
                                  this.selected.PATIENT_LEVEL_SHARING &&
                                  this.selected.PATIENT_LEVEL_SHARING.length <
                                    1)
                              }
                            >
                              {this.selected &&
                                this.selected.PATIENT_LEVEL_SHARING &&
                                this.selected.PATIENT_LEVEL_SHARING.map(
                                  (cond, index) => {
                                    return (
                                      <Checkbox
                                        key={index}
                                        value={cond.internalValue}
                                        id={cond.internalValue + idSuffix}
                                        name="patientLevel"
                                        displayvalue={cond.displayValue}
                                        setHashCallback={() =>
                                          this.setHashLocation(
                                            'filter-patient-data'
                                          )
                                        }
                                      />
                                    );
                                  }
                                )}
                            </div>
                            <Collapse isOpen={this.state.patientData}>
                              <div className={'boxes-style'}>
                                {patientData.map((item, index) => (
                                  <Col
                                    key={index}
                                    className={
                                      'check-box-align' + ' doc-section'
                                    }
                                  >
                                    <Checkbox
                                      value={item.InternalValue}
                                      id={item.InternalValue + idSuffix}
                                      name="patientLevel"
                                      displayvalue={item.DisplayValue}
                                      setHashCallback={() =>
                                        this.setHashLocation(
                                          'filter-patient-data'
                                        )
                                      }
                                    />
                                  </Col>
                                ))}
                              </div>
                            </Collapse>
                          </Col>
                        )}
                        <Col
                          className={'check-box-display'}
                          id="filter-patient-data"
                        >
                          <div
                            onClick={this.toggleVac}
                            className={'title-section'}
                          >
                            <h5>{'Vaccine Studies'}</h5>

                            <img
                              src="/icons/dropdown_arrow.svg"
                              alt="icon"
                              className={
                                this.state.collapseDoc === true
                                  ? 'arrow-position'
                                  : ''
                              }
                            />

                            {/* <h3>{data.advancedJson.availableDoc}</h3> */}
                          </div>
                          <div
                            className={'filter-items'}
                            hidden={
                              this.state.collapseVac === true ||
                              props.values.SponsorUnits.length < 1
                            }
                          >
                            {props.values.SponsorUnits.length > 0 ? (
                              <Checkbox
                                id={'SponsorUnits'}
                                value={'SPON_UNIT_VACCINES'}
                                displayvalue={'Vaccine Studies Only'}
                                name={'SponsorUnits'}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                          <Collapse isOpen={this.state.collapseVac}>
                            <div className={'boxes-style'}>
                              <Col
                                className={'check-box-align' + ' doc-section'}
                              >
                                <Checkbox
                                  id={'SponsorUnits'}
                                  value={'SPON_UNIT_VACCINES'}
                                  displayvalue={'Vaccine Studies Only'}
                                  name={'SponsorUnits'}
                                />
                              </Col>
                            </div>
                          </Collapse>
                        </Col>
                      </div>
                    </Row>
                  </Form>
                );
              }}
            />
          )}
        </Advanced>
      </Fonts>
    );
  }
}

AdvancedSearch.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
  searchCallBack: PropTypes.func,
  pageSearch: PropTypes.func,
  isMobileFilter: PropTypes.bool,
};

const AdvancedSearchQuery = props => (
  <StaticQuery
    query={graphql`
      query AdvancedSearch {
        advancedSearchJson {
          search {
            status
            phases
            age
            gender
            availableDoc
            studyType
            patientData
            medicalCondition
            clear
            filter
          }
        }
      }
    `}
    render={data => <AdvancedSearch data={data} {...props} />}
  />
);

AdvancedSearchQuery.propTypes = {
  children: PropTypes.node,
};

export default AdvancedSearchQuery;
