import styled from 'styled-components';

export const Advanced = styled.div`
  padding-top: 24px;
  margin-bottom: 30px;
  .filter-items {
    .radio-btn {
      .check {
        background-image: none;
      }
      input[type='checkBox']:checked ~ .check {
        background-image: url(/icons/close.svg);
        background-size: 12px;
        background-position-x: 6px;
        background-position-y: 4px;
      }
    }
    padding-bottom: 20px;
    max-height: 290px;
    overflow-y: auto;
  }
  .hero-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin-bottom: 37px;
    margin-top: 37px;
    p {
      color: #544f40;
      font-size: 16px;
      line-height: 21px;
      font-weight: bold;
    }
    .reset-button {
      color: #15717d;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
    }
  }
  .title-section {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    h5 {
      color: #f36533;
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;
      display: flex;
      p {
        color: #72635d;
        font-size: 14px;
        line-height: 20px;
        margin-left: 4px;
      }
    }
    img {
      height: 16px;
      width: 16px;
    }
  }
  .check-box-display {
    border-bottom: 1px solid #ced4da;
    flex-basis: auto;
  }
  .check-box-first {
    border-top: 1px solid #ced4da;
  }
  .check-box-align {
    padding: 0;
  }
  .cond-style {
    max-height: 325px;
    overflow: auto;
  }
  .arrow-position {
    transform: scaleY(-1);
  }
  .boxes-style {
    margin: 0 0 30px 0;
  }
  // label.padding-correction {
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  // }
  .check-box-display-children {
    margin-bottom: 10px;
    margin-left: 10px;
  }
  label.padding-correction {
    margin: 15px 0 0 8px;
  }
  div.check {
    margin-top: 13px !important;
    padding-right: 20px;
  }
  @media (min-width: 1025px) and (max-width: 1500px) {
    padding-left: 20px;
  }
  @media (min-width: 991px) and (max-width: 1160px) {
    margin-right: -40px;
  }
  
  //Mobile
  @media (min-width: 767px) and (max-width: 1024px) and (orientation: landscape) {
    .hero-filter {
      margin-top: 0px;
    }
`;
