/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Row, Col, Collapse } from 'reactstrap';
import Pagination from '../components/search/pagination';
import Layout from '../components/layout/layout';
import NProgress from 'nprogress';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { searchTrialUrl } from '../constants/config';
import { Link } from 'gatsby';
import axios from 'axios';
import { FaAngleDown, FaAngleUp, FaShareAlt, FaFilter } from 'react-icons/fa';
import {
  SearchHero,
  ResultsTable,
  PaginationStyle,
  SocialMediaResults,
  SearchResultsPage,
  ContainerHolder,
  FilterMobile,
} from '../global.css';
import Modal from '../components/modal/modal';
import { FaFacebookF, FaLinkedinIn, FaPrint, FaTwitter } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import AdvancedSearch from '../components/search/advanced-search';
import ContactUsForm from '../components/form/contact-us-form';
import FindATrial from '../components/search/find-a-trial';
import ClampLines from 'react-clamp-lines';
import BackToTop from '../components/back-to-top/back-to-top';
import {
  trackAnalytics,
  filterSearchEventProperties,
} from '../helpers/trackAnalytics';
import { MasterDataContext } from '../store/masterDataContext';

class TrialResults extends React.Component {
  static contextType = MasterDataContext;

  constructor(props) {
    if (props.data && props.data.searchResultJson) {
      props.data.trialsContent = props.data.searchResultJson;
    }
    super(props);

    this.state = {
      query: '',
      searchResults: [],
      titleToggle: {},
      totalResults: null,
      searchParams: null,
      isLoading: true,
      activeMarker: {},
      selectedPlace: {},
      showingInfoWindow: false,
      showFilter: false,
      showShareSection: true,
      modalFlag: false,
    };

    this.markers = [];
    this.cluster = null;
    this.isMobile = false;
    this.isMobileFilter = false;
    this.goToPage = this.goToPage.bind(this);
    this.showFilter = this.showFilter.bind(this);
    this.showFilter = this.showFilter.bind(this);
    this.showShareSection = this.showShareSection.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  getSearchPayload() {
    let { location } = this.props,
      searchParams = null;
    try {
      searchParams = new URL(window ? window.location.href : location.href)
        .searchParams;
    } catch (e) {
      searchParams = new URLSearchParams('');
    }

    let params = {
      PageSize: 16,
      PageIndex: 0,
      SortField: 'Location_Distance',
      SortOrder: 'asc',
      Keyword: '',
      Status: '',
      Longitude: '',
      Latitude: '',
      LocationName: '',
      ageRange: '',
      Conditions: '',
      TherapeuticArea: '',
      Treatment: '',
      documents: '',
      phases: '',
      SearchTerm: '',
      gender: '',
      patientLevel: '',
      HealthyVolunteer: '',
      PatientLevelSharing: '',
      studyType: '',
      StudyResults: '',
      Products: '',
      HasCTGovResult: '',
      Collaborators: '',
      MileRadius: '',
      SponsorUnits: '',
      UseAndOperatorForFullTextKeyWordSearch: false,
    };

    Object.keys(params).forEach(key => {
      if (searchParams.get(key)) {
        params[key] = searchParams.get(key);
      }
    });
    if (params.studyType.length) {
      params.StudyTypes = params.studyType;
    }
    if (params.phases.length) {
      params.Phases = params.phases;
    }
    if (params.ageRange.length) {
      params.AgeRanges = params.ageRange;
    }
    if (params.documents.length) {
      params.AttachmentTypes = params.documents;
    }
    if (params.Keyword.length) {
      params.SearchTerm = params.Keyword.replace(/[\u201C\u201D]/g, '"');
    }
    if (params.gender.length) {
      params.Gender = params.gender;
    }
    if (params.patientLevel.length) {
      params.PatientLevelSharing = params.patientLevel;
    }
    return params;
  }

  showFilter() {
    this.setState({
      showFilter: !this.state.showFilter,
    });
  }
  showShareSection() {
    this.setState({
      showShareSection: !this.state.showShareSection,
    });
  }

  componentDidMount() {
    this.makeSearchRequest(this.getSearchPayload());
    if (typeof window !== 'undefined') {
      this.isMobile = window.innerWidth && window.innerWidth < 768;
      this.isMobileFilter = window.innerWidth && window.innerWidth < 992;
      this.setState({
        isLoading: false,
      });
    }

    if (window !== undefined) {
      window.enableAnchors = false;
    }
  }

  componentWillUnmount() {
    if (this.markers && this.markers.length && this.cluster) {
      this.markers.forEach(marker => {
        this.cluster.removeMarker(marker);
      });
    }

    if (window !== undefined) {
      window.enableAnchors = false;
    }
  }

  showModal(index) {
    let { searchResults } = this.state;
    this.setState({
      searchResults: searchResults.map((item, idx) => {
        return {
          ...item,
          modalFlag: idx === index,
        };
      }),
    });
  }

  hideModal() {
    let { searchResults } = this.state;
    this.setState({
      searchResults: searchResults.map(item => {
        return {
          ...item,
          modalFlag: false,
        };
      }),
    });
  }

  makeSearchRequest(searchParams) {
    this.setState({ searchParams });
    NProgress.start();

    if (this.markers && this.markers.length && this.cluster) {
      this.markers.forEach(marker => {
        this.cluster.removeMarker(marker);
      });
    }

    const eventParams = filterSearchEventProperties(searchParams);
    axios
      .post(searchTrialUrl(), searchParams)
      .then(res => {
        NProgress.done();
        const searchResults = res.data.Data.map(row => {
          return {
            ...row,
            modalFlag: false,
          };
        });
        trackAnalytics('SearchResponse', {
          Count: res.data.Count,
          Success: res.data.Success,
          Context: res.data.Context,
          ...eventParams,
        });

        if (res.data.Success) {
          let titleToggle = {};
          res.data.Data.forEach(row => {
            titleToggle[row.Id] = false;
          });

          this.markers = [];

          this.setState({
            searchResults,
            titleToggle,
            totalResults: res.data.Count,
            showingInfoWindow: false,
            activeMarker: {},
          });
        }
      })
      .catch(error => {
        trackAnalytics('SearchResponse', {
          Error: error.message,
          Count: -1,
          Success: false,
          Context: '',
          ...eventParams,
        });
      });
  }

  orderByField(fieldName, direction = 'asc') {
    let { searchParams } = this.state;
    searchParams.SortField = fieldName;
    searchParams.SortOrder = direction;
    searchParams.PageIndex = 0;
    this.makeSearchRequest(searchParams);
    trackAnalytics('SearchOrder', {
      Field: fieldName,
      Direction: direction,
    });
  }

  goToPage(pageNumber) {
    let { searchParams } = this.state;
    searchParams.PageIndex = pageNumber.selected;
    this.makeSearchRequest(searchParams);
  }

  buildURL() {
    if (typeof window !== 'undefined' && this.state.searchParams !== null) {
      let { searchParams } = this.state;
      let url = Object.keys(searchParams)
        .map(
          k =>
            encodeURIComponent(k) +
            '=' +
            encodeURIComponent(
              Array.isArray(searchParams[k])
                ? searchParams[k].join('~')
                : `${searchParams[k]}`
            )
        )
        .join('&');
      return window.location.origin + window.location.pathname + '?' + `${url}`;
    }
  }

  buildDisplayFilters() {
    let { searchParams } = this.state;

    let displayData = [];

    if (!searchParams) {
      return [];
    }

    const masterData =
      this.context && this.context.masterData ? this.context.masterData : {};

    Object.values(searchParams).forEach(selectedValue => {
      let values = `${selectedValue}`.split('~');
      if (values.length) {
        values.forEach(internalValue => {
          Object.values(masterData).forEach(dataSection => {
            if (Array.isArray(dataSection)) {
              dataSection.forEach(item => {
                if (item.InternalValue === internalValue) {
                  if (
                    item.DisplayValue === 'No' ||
                    item.DisplayValue === 'Yes'
                  ) {
                    displayData.push(
                      'Accepting Healthy Volunteers: ' + item.DisplayValue
                    );
                  } else {
                    displayData.push(item.DisplayValue);
                  }
                }

                if (item.Children && Array.isArray(item.Children)) {
                  item.Children.forEach(subItem => {
                    if (subItem.InternalValue === internalValue) {
                      displayData.push(subItem.DisplayValue);
                    }
                  });
                }
              });
            }
          });
        });
      }
    });

    if (searchParams.SearchTerm && searchParams.SearchTerm.length) {
      displayData.push(`Keyword: "${searchParams.SearchTerm}"`);
    }

    if (searchParams.zip) {
      displayData.push(searchParams.zip);
    }

    if (searchParams.MileRadius && displayData.length && searchParams.country) {
      displayData.push(`${searchParams.MileRadius} miles`);
    }

    if (!displayData.length) {
      displayData.push('No filter');
    }

    return displayData;
  }

  filteringCondition = conditions => {
    let uniqCondition = [];
    return conditions.filter(cond => {
      if (!uniqCondition.includes(cond.DisplayValue)) {
        uniqCondition.push(cond.DisplayValue);
        return true;
      }
      return false;
    });
  };

  getBackLink() {
    let { pageContext } = this.props;
    let payload = this.getSearchPayload();
    return `/${pageContext.langKey}/find-a-trial?${Object.keys(payload)
      .map(key => `${key}=${encodeURIComponent(payload[key])}`)
      .join('&')}`;
  }

  renderTableRow(row, index) {
    let { pageContext, data } = this.props;

    return [
      <Tr
        key={'result_' + row.Id}
        className={
          'result-row ' + (index % 2 === 1 ? 'row-unpaired' : 'row-paired')
        }
      >
        <Td className={'condition-section'}>
          {row.Conditions
            ? this.filteringCondition(row.Conditions)
                .map(condition => condition.DisplayValue)
                .join(', ')
            : undefined}
        </Td>
        <Td className={'status-section'}>
          <div
            className={
              'status-icons ' +
              `${row.StatusInternal}`.toLocaleLowerCase() +
              (row.StatusLookup === null ? 'nullValue' : '')
            }
          >
            {' '}
            <div>
              {row.StudyTypeInternal === 'STUDY_TYPE_EXPANDEDACCESS'
                ? `${row.StatusLookup}`
                : row.StatusInternal === 'RECSTATUS_RELIQUISHED'
                ? 'No Longer a GSK Study'
                : `${row.StatusDisplay}`}
            </div>
          </div>
        </Td>
        <Td className={'requirements-section'}>
          <div
            className={
              ['All', 'Both'].indexOf(row.GenderLookup) >= 0
                ? 'both' + ' ageRange'
                : `${row.GenderLookup}`.toLocaleLowerCase() + ' ageRange'
            }
          >
            {' '}
            {row.MinAgeRaw !== null || row.MaxAgeRow !== null
              ? row.MinAgeRaw &&
                row.MaxAgeRaw &&
                !row.MaxAgeRaw.includes('N/A') &&
                !row.MinAgeRaw.includes('N/A')
                ? `${row.MinAgeRaw} - ${row.MaxAgeRaw}`
                : row.MinAgeRaw && !row.MinAgeRaw.includes('N/A')
                ? `${row.MinAgeRaw} + `
                : row.MaxAgeRaw && !row.MaxAgeRaw.includes('N/A')
                ? `0 - ${row.MaxAgeRaw}`
                : 'N/A'
              : row.MinAge && row.MaxAge
              ? `${row.MinAge} - ${row.MaxAge} years`
              : row.MinAge
              ? `${row.MinAge} year + `
              : row.MaxAge
              ? `0 - ${row.MaxAge} year`
              : 'N/A'}
          </div>
        </Td>
        <Td
          className={
            'distance-section' +
            (!row.Location_Distance && !this.state.searchParams.Longitude
              ? ' alt-text '
              : '')
          }
        >
          {row.Location_Distance !== undefined && row.Location_Distance !== null
            ? `${row.Location_Distance.toFixed(0)} ${
                row.Location_Distance.toFixed(0) === (1).toFixed(0)
                  ? 'mile'
                  : 'miles'
              }` +
              ' ' +
              `(${(row.Location_Distance * 1.60934).toFixed(0)} km)`
            : this.state.searchParams.Longitude &&
              ('' + this.state.searchParams.Longitude).length
            ? undefined
            : data.searchResultJson.noAddressSpecified}
        </Td>
        <Td className={'details-section'}>
          <Link
            className={'view-details'}
            onClick={() => {
              trackAnalytics('TrialDetailsClick', {
                TrialId: row.UniqueIdentifier,
                PositionInList: index,
                TrialTitle: row.Title,
                TrialStatus: row.StatusInternal,
                Conditions: row.Conditions.map(
                  condition => condition.InternalValue
                ),
              });
            }}
            to={
              `/${pageContext.langKey}/trial-details/?id=${row.UniqueIdentifier}` +
              (this.state.searchParams.Latitude.length &&
              this.state.searchParams.Latitude.length
                ? `&searchLat=${row.Location_Latitude}&searchLong=${row.Location_Longitude}`
                : '') +
              (this.state.searchParams.MileRadius.length
                ? `&searchRadius=${this.state.searchParams.MileRadius}&baseLat=${this.state.searchParams.Latitude}&baseLong=${this.state.searchParams.Longitude}`
                : '')
            }
          >
            View Details
          </Link>
          <Modal
            hasButton={true}
            open={row.modalFlag}
            showModal={() => {
              this.showModal(index);
              trackAnalytics('TrialContactClick', {
                TrialId: row.UniqueIdentifier,
                PositionInList: index,
                TrialTitle: row.Title,
                LocationPosition: -1,
                TrialStatus: row.StatusInternal,
                Conditions: row.Conditions.map(
                  condition => condition.InternalValue
                ),
              });
            }}
            hideModal={() => this.hideModal()}
            button={'Contact Us'}
            buttonType="simple"
            class="view-more-modal"
            type={'contactUs'}
          >
            <ContactUsForm
              itemId={row.UniqueIdentifier}
              title={row.Title}
              hideModal={() => this.hideModal()}
              headerTitle={'Contact Us'}
            />
          </Modal>
        </Td>
      </Tr>,
      // <Tr
      //   key={'result_second_row' + row.Id}
      //   className={
      //     'result-second-row ' +
      //     (index % 2 === 1 ? 'row-unpaired' : 'row-paired')
      //   }
      // />,
      <Tr
        className={
          (index % 2 === 1 ? 'row-unpaired' : 'row-paired') + ' clampRow'
        }
        key={row.Id}
      >
        <Td colSpan="5">
          <ClampLines
            key={row.Id}
            text={`${row.UniqueIdentifier} - ${row.Title}`}
            id="text"
            lines={this.isMobile ? 3 : 1}
            innerElement="div"
            ellipsis="..."
            moreText={<img src={'/icons/clamp.svg'} alt={''} />}
            lessText={
              <img src={'/icons/clamp.svg'} alt={''} className={'reverse'} />
            }
          />
        </Td>
      </Tr>,
    ];
  }

  renderResultsTable() {
    let { data } = this.props;
    if (!this.state.totalResults) {
      return undefined;
    }
    return (
      <ResultsTable style={{ minHeight: '20vh' }}>
        <Table className="results-table">
          {data.searchResultJson.tableHeader.map((headerItem, index) => (
            <Thead className={'table-header'} key={index}>
              <Tr>
                <Th className={'conditions desktop'}>
                  {headerItem.conditions}
                </Th>
                <Th
                  className={'sortable'}
                  onClick={() =>
                    this.orderByField(
                      'StatusDisplay',
                      this.state.searchParams.SortField === 'StatusDisplay'
                        ? this.state.searchParams.SortOrder === 'asc'
                          ? 'desc'
                          : 'asc'
                        : 'asc'
                    )
                  }
                >
                  {headerItem.status}
                  {this.state.searchParams.SortField === 'StatusDisplay' ? (
                    this.state.searchParams.SortOrder === 'asc' ? (
                      <FaAngleDown />
                    ) : (
                      <FaAngleUp />
                    )
                  ) : (
                    <FaAngleDown />
                  )}
                </Th>
                <Th className={'sortable'}>{headerItem.requirements}</Th>
                <Th
                  className={'sortable location'}
                  onClick={() =>
                    this.orderByField(
                      'Location_Distance',
                      this.state.searchParams.SortField === 'Location_Distance'
                        ? this.state.searchParams.SortOrder === 'asc'
                          ? 'desc'
                          : 'asc'
                        : 'asc'
                    )
                  }
                >
                  {headerItem.distance}
                  {this.state.searchParams.SortField === 'Location_Distance' ? (
                    this.state.searchParams.SortOrder === 'asc' ? (
                      <FaAngleDown />
                    ) : (
                      <FaAngleUp />
                    )
                  ) : (
                    <FaAngleDown />
                  )}
                </Th>
                <Th>&nbsp;</Th>
              </Tr>
            </Thead>
          ))}

          <Tbody className={'table-body'}>
            {this.state.searchResults.map(this.renderTableRow.bind(this))}
          </Tbody>
        </Table>
      </ResultsTable>
    );
  }

  render() {
    let { data } = this.props;
    let displayFilters = this.buildDisplayFilters();
    let canonical = this.buildURL();

    return this.state.isLoading ? (
      ''
    ) : (
      <div>
        {this.state.showFilter === false ? (
          <Layout
            meta={{
              pageTitle: 'Clinical Trial Results | GSK Clinical Trials',
              pageDescription: `${
                displayFilters.length
                  ? 'Clinical trials for: '
                  : 'All clinical trials for GSK Study Register'
              }${displayFilters.join(', ')}`,
              canonical: canonical,
            }}
          >
            <SearchResultsPage>
              <SearchHero>
                <ContainerHolder>
                  <Row
                    className={'hero-content'}
                    style={{ maxWidth: '1440px' }}
                  >
                    <Col lg={10} md={8} sm={8} className={'hero-text'}>
                      <h1>{data.searchResultJson.title}</h1>
                      <h4 className={'hero-counter'}>
                        {data.searchResultJson.heroText}&nbsp;
                        {this.state.totalResults}&nbsp;
                        {data.searchResultJson.hero2Text}
                      </h4>
                    </Col>
                    <Col lg={2} md={4} sm={12} className={'social-media'}>
                      <div
                        className={
                          this.state.showShareSection === false
                            ? 'mobile-share closed'
                            : 'mobile-share open'
                        }
                        onClick={this.showShareSection}
                      >
                        <div className={'shareSvgPlaceholder'}>
                          <FaShareAlt className={'mobileShare-btn'} />
                        </div>

                        <p>Share this search</p>
                      </div>
                      <SocialMediaResults
                        className={
                          this.state.showShareSection === true
                            ? 'closedShare'
                            : 'openShare'
                        }
                      >
                        <p className={'mobileShareSection'}>
                          Share this search{' '}
                        </p>
                        <div className={'media-row'}>
                          <a
                            href={`mailto:?subject=${
                              data.searchResultJson.shareByEmail.subject
                            }&body=${data.searchResultJson.shareByEmail.body} ${
                              typeof window !== 'undefined' &&
                              window.location &&
                              window.location.href
                                ? encodeURIComponent(window.location.href)
                                : ''
                            }`}
                            rel="noopener noreferrer"
                            target="_top"
                          >
                            <MdEmail />
                          </a>
                          <button
                            className={'print-button'}
                            onClick={event => {
                              event.preventDefault();
                              window.print();
                            }}
                          >
                            <FaPrint />
                          </button>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                              'https://www.linkedin.com/shareArticle?mini=true&url=' +
                              encodeURIComponent(window.location.href)
                            }
                          >
                            <FaLinkedinIn />
                          </a>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                              'https://www.facebook.com/sharer/sharer.php?u=' +
                              encodeURIComponent(window.location.href)
                            }
                          >
                            <FaFacebookF />
                          </a>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                              'https://twitter.com/share?url=' +
                              encodeURIComponent(window.location.href)
                            }
                          >
                            <FaTwitter />
                          </a>
                        </div>
                      </SocialMediaResults>
                    </Col>
                    <Col md={12} className={'searchBox'}>
                      <FindATrial
                        onResults={true}
                        navigate={this.props.navigate}
                        location={this.props.location}
                        pageContext={this.props.pageContext}
                        searchCallBack={() => {
                          this.makeSearchRequest(this.getSearchPayload());
                        }}
                      />{' '}
                    </Col>
                  </Row>
                </ContainerHolder>
              </SearchHero>
              <div className={'content-holder'}>
                <Row>
                  <Col className={'advanced-filter'} lg={3} md={12} sm={12}>
                    <AdvancedSearch
                      location={this.props.location}
                      pageContext={this.props.pageContext}
                      searchCallBack={() => {
                        this.makeSearchRequest(this.getSearchPayload());
                      }}
                      isMobileFilter={this.isMobileFilter}
                    />
                  </Col>
                  <Col className={'mobile'} xs={12}>
                    <div
                      id="filter-mobile"
                      onClick={() => {
                        this.showFilter();
                        if (window !== undefined && window.location) {
                          window.location.hash = '';
                        }
                      }}
                    >
                      <div className={'icon'}>
                        <FaFilter />
                      </div>
                      <p>{'Filter'}</p>
                    </div>
                  </Col>
                  <Col lg={9} md={12} sm={12} className={'results-Section'}>
                    {this.state.totalResults &&
                    this.state.totalResults !== null ? (
                      <PaginationStyle>
                        <div className={'results'}>
                          {'Showing '}{' '}
                          {this.state.searchResults &&
                          this.state.searchResults.length
                            ? `${this.state.searchParams.PageIndex *
                                this.state.searchParams.PageSize +
                                1}- ${Math.min(
                                this.state.searchParams.PageIndex *
                                  this.state.searchParams.PageSize +
                                  this.state.searchParams.PageSize,
                                this.state.totalResults
                              )} of `
                            : undefined}
                          {this.state.totalResults}{' '}
                          {data.searchResultJson.results}
                        </div>
                        <Pagination
                          reactPaginate={true}
                          activePage={this.state.searchParams.PageIndex + 1}
                          itemsCountPerPage={this.state.searchParams.PageSize}
                          totalItemsCount={this.state.totalResults}
                          onChange={this.goToPage}
                        />
                      </PaginationStyle>
                    ) : (
                      undefined
                    )}
                    <div>
                      {this.state.totalResults >= 1 ? (
                        <div />
                      ) : this.state.totalResults === 0 ? (
                        <div className={'no-results-message'}>
                          Sorry, the criteria specified did not match any Trials
                        </div>
                      ) : (
                        undefined
                      )}
                      {this.renderResultsTable()}
                    </div>
                    {this.state.totalResults &&
                    this.state.totalResults !== null ? (
                      <PaginationStyle className={'bottom-pagination'}>
                        <div className={'results'}>
                          {'Showing '}{' '}
                          {this.state.searchResults &&
                          this.state.searchResults.length
                            ? `${this.state.searchParams.PageIndex *
                                this.state.searchParams.PageSize +
                                1}- ${Math.min(
                                this.state.searchParams.PageIndex *
                                  this.state.searchParams.PageSize +
                                  this.state.searchParams.PageSize,
                                this.state.totalResults
                              )} of `
                            : undefined}
                          {this.state.totalResults}{' '}
                          {data.searchResultJson.results}
                        </div>
                        <Pagination
                          reactPaginate={true}
                          activePage={this.state.searchParams.PageIndex + 1}
                          itemsCountPerPage={this.state.searchParams.PageSize}
                          totalItemsCount={this.state.totalResults}
                          onChange={this.goToPage}
                        />
                      </PaginationStyle>
                    ) : (
                      undefined
                    )}
                  </Col>
                </Row>
              </div>
            </SearchResultsPage>
            <BackToTop />
          </Layout>
        ) : null}

        <Collapse isOpen={this.state.showFilter}>
          <FilterMobile>
            <div className={'content-sec'}>
              <AdvancedSearch
                location={this.props.location}
                pageContext={this.props.pageContext}
                searchCallBack={() => {
                  this.makeSearchRequest(this.getSearchPayload());
                }}
                isMobileFilter={this.isMobileFilter}
              />
            </div>
            <div className={'footer-sec'}>
              <button
                className={'apply'}
                onClick={() => {
                  this.showFilter();
                  if (window !== undefined && window.location) {
                    window.enableAnchors = true;
                    window.location.hash = 'filter-mobile';
                  }
                }}
              >
                Apply
              </button>
              <button
                className={'clearBTN'}
                onClick={() => {
                  this.props.navigate(
                    '/en/results/?ageRange=&phases=&documents=&Keyword=&studyType=&gender=&HasCTGovResult=&patientLevel=&Status=&Latitude=&Longitude=&MileRadius=100&Conditions='
                  ) &&
                    window &&
                    window.location.reload();
                }}
              >
                Clear
              </button>
            </div>
          </FilterMobile>
        </Collapse>
      </div>
    );
  }
}

TrialResults.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
  pageContext: PropTypes.object.isRequired,
  navigate: PropTypes.func,
  google: PropTypes.object.isRequired,
};

export default TrialResults;

export const query = graphql`
  query SearchResultsQuery {
    searchResultJson {
      title
      socialTitle
      viewDetails
      askToJoinBtn
      heroText
      hero2Text
      tableHeader {
        status
        conditions
        distance
        requirements
      }
      showResult
      results
      noAddressSpecified
      shareByEmail {
        subject
        body
      }
    }
  }
`;
