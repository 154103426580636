import React from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import ReactPaginate from 'react-paginate';

class ResultsPagination extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { simple = false } = this.props;
    let { reactPaginate = false } = this.props;

    if (simple) {
      let numberOfPages = Math.ceil(
        this.props.totalItemsCount / this.props.itemsCountPerPage
      );

      return (
        <div className="simple-pagination">
          {this.props.activePage > 1 && (
            <button
              onClick={() =>
                this.props.onChange &&
                this.props.onChange(this.props.activePage - 1)
              }
              className="pagination-arrow left"
              type="button"
            >
              <IoIosArrowBack />
            </button>
          )}
          <div className={'counters'}>
            {this.props.activePage < numberOfPages ? (
              <span className="current-page">{this.props.activePage}</span>
            ) : (
              undefined
            )}
            {this.props.activePage < numberOfPages ? <em> - </em> : undefined}
            <span className="current-page">
              {numberOfPages > 1 ? numberOfPages : null}
            </span>
          </div>
          {this.props.activePage < numberOfPages && (
            <button
              onClick={() =>
                this.props.onChange &&
                this.props.onChange(this.props.activePage + 1)
              }
              className="pagination-arrow right"
              type="button"
            >
              <IoIosArrowForward />
            </button>
          )}
        </div>
      );
    } else if (reactPaginate) {
      return (
        <ReactPaginate
          previousLabel={' '}
          nextLabel={' '}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={Math.ceil(
            this.props.totalItemsCount / this.props.itemsCountPerPage
          )}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          onPageChange={this.props.onChange}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          forcePage={this.props.activePage - 1}
        />
      );
    } else {
      return (
        <Pagination
          activePage={this.props.activePage}
          itemsCountPerPage={this.props.itemsCountPerPage}
          totalItemsCount={this.props.totalItemsCount}
          nextPageText="NEXT"
          prevPageText="PREVIOUS"
          hideFirstLastPages={true}
          pageRangeDisplayed={4}
          itemClass="page-item"
          linkClass="page-link"
          linkClassNext="text-link"
          linkClassPrev="text-link"
          onChange={this.props.onChange}
        />
      );
    }
  }
}

ResultsPagination.propTypes = {
  reactPaginate: PropTypes.bool,
  simple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  activePage: PropTypes.number.isRequired,
  itemsCountPerPage: PropTypes.number.isRequired,
  totalItemsCount: PropTypes.number.isRequired,
};

export default ResultsPagination;
